import { startCase } from 'lodash';
import { useMemo } from 'react';
import { ProductTypeEnum } from '../../../../types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

/**
 * This hook creates the filter property definition for the ProductTypeEnum.
 */
export function useProductTypesFilter() {
  return useMemo(
    () =>
      ({
        key: 'AssetTypes',
        label: 'Product',
        icon: IconName.CubeTransparent,
        control: 'multi-select',
        options: Object.keys(ProductTypeEnum),
        getOptionLabel: (option: string) => startCase(option),
      } as const satisfies FilterableProperty),
    []
  );
}
