import {
  Card,
  CurrencyRenderer,
  formattedUTCDate,
  getTypedKeys,
  HStack,
  InlineFormattedNumber,
  ProductTypeEnum,
  SettleValueTypeEnum,
  Text,
  toBigWithDefault,
  useCurrenciesContext,
  useHomeCurrencyRateValue,
  useSecuritiesContext,
  VStack,
} from '@talos/kyoko';
import { GREEK_ORDER } from 'containers/Portfolio/PortfolioManagement/types/types';
import { compact } from 'lodash';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import type { ReactNode } from 'react';
import type { PortfolioRiskDataItem } from '../../../types/PortfolioRiskDataItem';

type DisplayOutputItem = {
  sectionTitle: string;
  fields: Array<{
    fieldName: string;
    fieldValue: ReactNode;
  }>;
};

type DisplayOutputs = Array<DisplayOutputItem>;

export const InspectorDataSections = ({ dataItem }: { dataItem: PortfolioRiskDataItem }) => {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { currenciesBySymbol } = useCurrenciesContext();
  const { homeCurrency } = useDisplaySettings();
  const displayOutputs: DisplayOutputs = [];

  const security = securitiesBySymbol.get(dataItem.gridData.Asset);
  const underlying = security?.BaseCurrency ? currenciesBySymbol.get(security?.BaseCurrency) : undefined;
  const homeCurrencyRateValue = useHomeCurrencyRateValue(security?.BaseCurrency);

  if (!dataItem) {
    return null;
  }
  if (security) {
    const settlementCurrency = currenciesBySymbol.get(security.SettlementCurrency);
    const quoteCurrency = currenciesBySymbol.get(security.QuoteCurrency);

    displayOutputs.push({
      sectionTitle: 'Currency Details',
      fields: compact([
        underlying
          ? {
              fieldName: 'Underlying',
              fieldValue: <CurrencyRenderer currency={underlying} colorful />,
            }
          : undefined,
        underlying // note: only check for underlying: if the homeCurrencyRateValue value hasn't come thru yet, this still reserve the space for it, avoiding flicker
          ? {
              fieldName: 'Underlying Price',
              fieldValue: (
                <InlineFormattedNumber
                  number={homeCurrencyRateValue?.Rate}
                  align="right"
                  trimTrailingZeroes
                  currency={homeCurrency}
                />
              ),
            }
          : undefined,
        settlementCurrency
          ? {
              fieldName: 'Settlement Currency',
              fieldValue: <CurrencyRenderer currency={settlementCurrency} colorful />,
            }
          : undefined,
        quoteCurrency
          ? {
              fieldName: 'Quote Currency',
              fieldValue: <CurrencyRenderer currency={quoteCurrency} colorful />,
            }
          : undefined,
      ]),
    });

    const contractDetails: DisplayOutputItem = {
      sectionTitle: 'Contract Details',
      fields: [],
    };

    if (security.ProductType === ProductTypeEnum.Option) {
      contractDetails.fields.push(
        {
          fieldName: 'Option Type',
          fieldValue: security.OptionType,
        },
        {
          // Options Style purposefully hard-coded to European for clarity
          fieldName: 'Option Style',
          fieldValue: 'European',
        },
        {
          fieldName: 'Expiry',
          fieldValue: formattedUTCDate(security.Expiration) + ' UTC',
        },
        {
          fieldName: 'Strike Price',
          fieldValue: (
            <InlineFormattedNumber number={security.StrikePrice} currency={security.UnderlyingQuoteCurrency} />
          ),
        }
      );
    } else if (security.Expiration) {
      contractDetails.fields.push({
        fieldName: 'Expiry',
        fieldValue: formattedUTCDate(security.Expiration) + ' UTC',
      });
    }

    contractDetails.fields.push(
      ...[
        {
          fieldName: 'Contract Size',
          fieldValue: <InlineFormattedNumber number={toBigWithDefault(security.NotionalMultiplier, 1)} />,
        },
        {
          fieldName: 'Payoff Type',
          fieldValue: security.SettleValueType === SettleValueTypeEnum.Regular ? 'Linear' : security.SettleValueType,
        },
      ]
    );
    displayOutputs.push(contractDetails);
  }

  const valuedRawGreeks = getTypedKeys(GREEK_ORDER)
    .map(greek => ({
      fieldName: greek,
      fieldValue: dataItem.gridData[greek],
    }))
    .filter(({ fieldValue }) => fieldValue != null && fieldValue !== '0')
    .map(({ fieldName, fieldValue }) => ({
      fieldName,
      fieldValue: <InlineFormattedNumber number={fieldValue} align="right" trimTrailingZeroes increment="0.0001" />,
    }));

  if (valuedRawGreeks.length > 0) {
    displayOutputs.push({
      sectionTitle: 'Raw Greeks',
      fields: valuedRawGreeks,
    });
  }

  return displayOutputs.map(({ sectionTitle, fields }) => (
    <VStack
      data-testid={`inspector-section-${sectionTitle}`}
      key={sectionTitle}
      gap="spacingTiny"
      w="100%"
      alignItems="stretch"
    >
      <Card display="flex" flexDirection="column">
        <Text color="colorTextImportant" size="fontSizeMd">
          {sectionTitle}
        </Text>
      </Card>
      <Card key={sectionTitle} display="flex" flexDirection="column">
        <VStack gap="spacingDefault" w="100%" alignItems="stretch">
          {fields.map(({ fieldName, fieldValue }) => (
            <HStack key={fieldName} justifyContent="space-between">
              <Text>{fieldName}</Text>
              <Text color="colorTextImportant" size="fontSizeMd">
                {fieldValue}
              </Text>
            </HStack>
          ))}
        </VStack>
      </Card>
    </VStack>
  ));
};
