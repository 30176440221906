import { Drawer, type DrawerProps } from '@talos/kyoko';
import type { PortfolioRiskDataItem } from 'containers/Portfolio/PortfolioManagement/types/PortfolioRiskDataItem';
import { InspectorDrawerContent } from './InspectorDrawerContent';

export type InspectorDrawerProps = DrawerProps & {
  dataItem?: PortfolioRiskDataItem;
};

export const InspectorDrawer = (props: InspectorDrawerProps) => {
  const { dataItem, ...drawerProps } = props;
  return (
    <Drawer {...drawerProps}>
      <InspectorDrawerContent dataItem={dataItem} {...drawerProps} />
    </Drawer>
  );
};
