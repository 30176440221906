import type { Position, TreeRow } from '@talos/kyoko';
import type { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { getPortfolioRiskHierarchyFormattedValue } from '../components/PortfolioRiskBlotter/useRollupTreeGridBuilders';
import type { BasePortfolioManagementDataItem } from './BasePortfolioManagementDataItem';
import type { PortfolioRiskDataItem } from './PortfolioRiskDataItem';
import type { PortfolioRiskGridData } from './PortfolioRiskGridData';
import type { PortfolioRiskSubscriptionData } from './PortfolioRiskSubscriptionData';

export class PortfolioManagementBlotterDataItem implements BasePortfolioManagementDataItem, TreeRow {
  dataPath: string[];
  skipAggregation: boolean;
  rowID: string;

  gridData: PortfolioRiskGridData;
  riskData: PortfolioRiskSubscriptionData;
  position?: Position;

  groupColumnValueGetter(params: ValueGetterParams<PortfolioRiskDataItem>): string {
    if (!params.node) {
      return '';
    }

    return getPortfolioRiskHierarchyFormattedValue(params.node, params.context.current);
  }

  groupColumnValueFormatter({ value, context }: ValueFormatterParams<PortfolioRiskDataItem, string>): string {
    return value ?? '';
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  constructor(data: PortfolioRiskDataItem, position?: Position) {
    const gridData = data.gridData;
    this.dataPath = gridData.dataPath;
    this.skipAggregation = gridData.skipAggregation;
    this.rowID = gridData.rowID;
    this.gridData = gridData;
    this.position = position;
    this.riskData = data.riskData;
  }
}
