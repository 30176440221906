import {
  filterExistsAndExcludes,
  ProductTypeEnum,
  toBigWithDefault,
  useObservable,
  useObservableValue,
  useSecuritiesContext,
  useSyncedRef,
  useWSFilterPipe,
  wsScanToMap,
  wsSubscriptionCache,
  type Position,
} from '@talos/kyoko';
import Big from 'big.js';
import { createContext, useCallback, useContext, useMemo, type PropsWithChildren } from 'react';
import { asyncScheduler, map, shareReplay, throttleTime, type Observable } from 'rxjs';
import type { PositionsTableFilter } from '../../../Blotters/PositionsV3/types';
import { useUnifiedPositionsBalancesObs } from '../../../Blotters/PositionsV3/Unified/useUnifiedPositionsBalancesObs';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { OpsPosition } from '../types';

export const OperationsOverviewPositionsContext = createContext<OperationsOverviewPositionsContextProps | undefined>(
  undefined
);

interface PositionsData {
  positions: OpsPosition[];
}

export type OperationsOverviewPositionsContextProps = {
  positionsData: PositionsData | undefined;
  positionsDataObs: Observable<PositionsData>;
};

export function useOperationsOverviewPositions() {
  const context = useContext(OperationsOverviewPositionsContext);
  if (context === undefined) {
    throw new Error(
      'Missing OperationsOverviewPositionsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

function getPositionKey(p: Position) {
  return p.rowID;
}

export const OperationsOverviewPositionsProvider = function OperationsOverviewPositionsProvider({
  children,
}: PropsWithChildren) {
  const { opsOverviewFilter, showZeroBalances, selectedMarketAccountIds } = usePortfolioViewStateSelector();
  const filter: PositionsTableFilter = useMemo(() => {
    return {
      ...opsOverviewFilter,
      MarketAccounts: selectedMarketAccountIds,
    };
  }, [opsOverviewFilter, selectedMarketAccountIds]);

  const backendFilter: PositionsTableFilter = useMemo(
    () => ({
      MarketAccounts: filter.MarketAccounts,
      Symbols: filter.Symbols,
    }),
    [filter.MarketAccounts, filter.Symbols]
  );

  const positionsObs = useUnifiedPositionsBalancesObs({
    tag: 'operations-overview-page',
    filter: backendFilter,
    showZeroBalances,
  });

  const { securitiesBySymbol } = useSecuritiesContext();
  const securitiesBySymbolRef = useSyncedRef(securitiesBySymbol);

  const filterFunc = useCallback(
    (item: Position) => {
      if (filterExistsAndExcludes({ Markets: filter.Markets }, 'Markets', item, 'market')) {
        return false;
      }

      if (filterExistsAndExcludes({ AssetTypes: filter.AssetTypes }, 'AssetTypes', item, 'AssetType')) {
        return false;
      }

      return true;
    },
    [filter.Markets, filter.AssetTypes]
  );
  const wsFilterPipe = useWSFilterPipe({ getUniqueKey: getPositionKey, filterFunc });

  const cachedPositionsObs = useMemo(
    () =>
      positionsObs.pipe(
        wsFilterPipe,
        wsSubscriptionCache(pos => pos.rowID)
      ),
    [positionsObs, wsFilterPipe]
  );

  const positionsDataObs = useObservable(
    () =>
      cachedPositionsObs.pipe(
        wsScanToMap({ getUniqueKey: pos => pos.rowID, newMapEachUpdate: false }),
        // add in a bit of a throttle so we dont spam recalculating aggregate values, redrawing charts, etc
        throttleTime(1000, asyncScheduler, { leading: true, trailing: true }),
        map(mapping => [...mapping.values()]),
        map(positions => {
          // Mapping of each market account name -> Big (aggregated position within the mkt acc)
          const totals = getTotalPositionPerMarketAccount(positions);
          const opsPositions = positions.map(
            pos =>
              new OpsPosition(pos, {
                underlying:
                  pos.AssetType === ProductTypeEnum.Spot
                    ? pos.Asset
                    : securitiesBySymbolRef.current.get(pos.Asset)?.BaseCurrency ?? 'Unknown',
                marketAccountTotal: totals.get(pos.MarketAccount) ?? Big(0),
              })
          );
          return {
            positions: opsPositions,
          };
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      ),
    [cachedPositionsObs, securitiesBySymbolRef]
  );

  const positionsData = useObservableValue(() => {
    return positionsDataObs;
  }, [positionsDataObs]);

  const value = useMemo(() => {
    return {
      positionsData,
      positionsDataObs,
    };
  }, [positionsData, positionsDataObs]);

  return (
    <OperationsOverviewPositionsContext.Provider value={value}>{children}</OperationsOverviewPositionsContext.Provider>
  );
};

function getTotalPositionPerMarketAccount(positions: Position[]): Map<string, Big> {
  return positions.reduce((agg, position) => {
    const mktAccSumSoFar = agg.get(position.MarketAccount) ?? Big(0);
    agg.set(position.MarketAccount, mktAccSumSoFar.plus(toBigWithDefault(position.Equivalent?.Amount, 0).abs()));
    return agg;
  }, new Map<string, Big>());
}
