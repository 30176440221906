import { invariant } from '@epic-web/invariant';
import {
  getTypedEntries,
  getTypedKeys,
  IconName,
  type AgGridIconButtonProps,
  type Column,
  type ColumnDef,
  type ISubaccount,
  type Leaves,
  type SizeColumnParams,
  type Tree,
  type ValueOf,
} from '@talos/kyoko';
import {
  AGGRID_AUTOCOLUMN_ID,
  COLUMN_GROUP_NATIVE_TYPE,
  extractColumns,
  type ColumnGroup,
  type ColumnOrColumnGroup,
} from '@talos/kyoko/src/components/BlotterTable/types';
import { useDefaultColumnsWithGroupings } from '@talos/kyoko/src/components/BlotterTable/useDefaultColumnsWithGroupings';
import { useRollupTreeRef } from 'hooks';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import type { BasePortfolioManagementDataItem } from '../../types/BasePortfolioManagementDataItem';
import type { PortfolioRiskDataItem } from '../../types/PortfolioRiskDataItem';
import {
  GREEK_DISPLAY_NAME_MAP,
  GREEK_ORDER,
  MONEYNESS_COLUMN_MAP,
  NON_PIVOT_DISPLAY_COLUMNS,
  RISK_AGG_MODES,
  TENOR_COLUMN_MAP,
  UNDERLYING_GROUP_PREFIX,
  type Greeks,
  type MoneynessServerTypes,
  type RiskAggMode,
  type RiskPivotType,
  type TenorServerTypes,
} from '../../types/types';

type SplitField =
  | [unknown, unknown, unknown, 'Tenor', TenorServerTypes, RiskAggMode]
  | [unknown, unknown, unknown, 'Moneyness', MoneynessServerTypes, RiskAggMode];
export function splitPivotField(field: string): SplitField {
  const splitValue = field.split('.');
  invariant(splitValue.length === 6, `Pivot column fields (${field}) must have 6 parts`);
  return splitValue as SplitField;
}

function capitalizeFirstChar(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

/** Business logic - for pivots, aggregate DeltaExposure and Theta all the way up, everything else up to underlier only */
const fullAggregationGreeks: Greeks[] = ['Theta'];

function buildVisibleColumns(homeCurrency: string) {
  const nonPivotColumnPrefixes = getTypedKeys(NON_PIVOT_DISPLAY_COLUMNS);
  // TODO: Add Generic typing to SizeColumnParams so this properly can tie to the grid data
  /** Custom Aggregate checker to ensure that pivot aggregations only flow up to the underlying level */
  const aggregatePivotIf: SizeColumnParams['aggregateIf'] = params => {
    // only aggregate if we are in a proper group node
    if (!params.rowNode?.group || params.rowNode.level < 0) {
      return false;
    }
    const aggField: string = params.rowNode?.groupData?.[AGGRID_AUTOCOLUMN_ID];
    if (typeof aggField !== 'string') {
      throw new Error('AggregateIf (pivot) column must have a field');
    }

    const colDefField = params.colDef.field;
    invariant(colDefField, `AggregateIf (pivot) column must have a field definition`);
    if (nonPivotColumnPrefixes.some(prefix => colDefField.startsWith(prefix))) {
      return true;
    }
    const fieldSplit = splitPivotField(colDefField);
    const [_a, _b, greek, _pivotType, _bucket, _netGross] = fieldSplit;
    if (fullAggregationGreeks.includes(greek as Greeks)) {
      return true;
    }
    if (aggField.startsWith(UNDERLYING_GROUP_PREFIX)) {
      return true;
    }
    return false;
  };

  type HeaderDisplayTypes =
    | {
        [P in TenorServerTypes]: {
          header: string;
          tooltip?: string;
        };
      }
    | {
        [P in MoneynessServerTypes]: {
          header: string;
          tooltip?: string;
        };
      };

  const commonPivotColumnProps: Column = {
    type: 'size',
    // dynamic hide/show processing happens in usePivotColumnShowHide
    hide: true,
    aggregate: true,
    // same as the pivot column group, we don't want to show these in the column tool panel
    suppressColumnsToolPanel: true,
    params: {
      currencyField: 'gridData.Equivalent.Currency' satisfies Leaves<PortfolioRiskDataItem>,
      increment: '0.01',
      round: true,
      highlightNegative: true,
      aggregateIf: aggregatePivotIf,
      aggregateEmptyValues: true,
    } satisfies SizeColumnParams,
  };

  // For now this is strictly tied to Delta Exposure, but this could be expanded to other greeks soon
  function buildSimpleNetGrossColumns(): Column[] {
    return RISK_AGG_MODES.flatMap(netGross => {
      // if this grows, loop over the NON_PIVOT_DISPLAY_COLUMNS object
      const colCreator = NON_PIVOT_DISPLAY_COLUMNS['gridData.Equivalent.CcyDeltaExposure'];
      const col: Column = {
        field: colCreator.field(netGross),
        title: colCreator.title(netGross, homeCurrency),
        description: colCreator.description(netGross, homeCurrency),
        ...commonPivotColumnProps,
      };
      return col;
    });
  }

  function buildPivotColumns(type: RiskPivotType, recordMap: HeaderDisplayTypes): ColumnGroup[] {
    return getTypedEntries(GREEK_ORDER).map(([greek, greekInfo]) => {
      return {
        groupId: `${type}_${greek}`,
        type: COLUMN_GROUP_NATIVE_TYPE,
        headerName: GREEK_DISPLAY_NAME_MAP[greek],
        // the group header tooltip has no Net/Gross starter, so capitalize the first letter
        headerTooltip: `${capitalizeFirstChar(greekInfo.tooltipBase(homeCurrency))}`,
        // since pivot columns are dynamic, we don't want to show them in the column tool panel
        suppressColumnsToolPanel: true,
        children: (['Net', 'Gross'] as const satisfies RiskAggMode[]).flatMap(netGross => {
          return getTypedEntries(recordMap).map(
            // this is a case where getTypedEntries is not exact, since it's a union type
            ([serverType, displayDetails]: [TenorServerTypes | MoneynessServerTypes, ValueOf<typeof recordMap>]) => {
              const col: Column = {
                field: `gridData.Pivot.${greek}.${type}.${serverType}.${netGross}`,
                title: displayDetails.header,
                description: `${netGross} ${greekInfo.tooltipBase(homeCurrency)} ${displayDetails.tooltip ?? ''}`,
                ...commonPivotColumnProps,
              };
              return col;
            }
          );
        }),
      };
    });
  }

  const DELTA_EXPOSURE_COLUMNS: Column[] = buildSimpleNetGrossColumns();
  const TENOR_COLUMNS: ColumnGroup[] = buildPivotColumns('Tenor', TENOR_COLUMN_MAP);
  const MONEYNESS_COLUMNS: ColumnGroup[] = buildPivotColumns('Moneyness', MONEYNESS_COLUMN_MAP);

  const visibleColumnsResult: Array<
    keyof ReturnType<typeof usePortfolioRiskColumnDefs>['mapping'] | Column | ColumnGroup
  > = [
    'pmsWarnings',
    'weight',
    'position',
    'positionHome',
    'price',
    ...DELTA_EXPOSURE_COLUMNS,
    ...TENOR_COLUMNS,
    ...MONEYNESS_COLUMNS,
  ];
  return visibleColumnsResult;
}

export function isPortfolioRiskInspectorRow(
  data: BasePortfolioManagementDataItem | undefined
): data is BasePortfolioManagementDataItem {
  return data != null;
}

export const usePortfolioRiskColumns = (
  onInspectorOpen: (data: PortfolioRiskDataItem) => void
): {
  defaultColumns: ColumnOrColumnGroup[];
  defaultColumnsFlat: Column[];
} => {
  const { homeCurrency } = useDisplaySettings();
  const rollupTreeRef = useRollupTreeRef();

  const visibleColumns = useMemo(() => buildVisibleColumns(homeCurrency), [homeCurrency]);

  const { colDefsMap } = usePortfolioRiskColumnDefs(rollupTreeRef, homeCurrency, onInspectorOpen);
  const defaultColumns = useDefaultColumnsWithGroupings(visibleColumns, colDefsMap);
  const flatDefultColumns = useMemo(() => {
    return extractColumns(defaultColumns);
  }, [defaultColumns]);
  return {
    defaultColumns,
    defaultColumnsFlat: flatDefultColumns,
  };
};

function usePortfolioRiskColumnDefs(
  rollupTreeRef: React.MutableRefObject<Tree<ISubaccount>>,
  homeCurrency: string,
  onInspectorOpen: (data: PortfolioRiskDataItem) => void
) {
  return useMemo(() => {
    const mapping = {
      pmsWarnings: {
        id: 'warnings',
        field: 'gridData.PMSWarningColumnValue',
        type: 'pmsWarnings',
        title: { intlKey: 'warning' },
        aggregate: true,
        pinned: 'left',
      },
      subAccount: {
        id: 'SubAccount',
        field: 'gridData.SubAccount',
        title: 'SubAccount',
        type: 'subAccountName',
        sortable: true,
        hide: true,
        params: {
          rollupTree: rollupTreeRef,
        },
      },
      assetField: {
        id: 'Asset',
        field: 'gridData.Asset',
        type: 'asset',
        pinned: 'left',
        sortable: true,
        width: 150,
        hide: true,
        title: 'Asset',
        params: {
          assetTypeField: 'gridData.AssetType' satisfies Leaves<PortfolioRiskDataItem>,
          colorful: true,
        },
      },
      weight: {
        id: 'Weight',
        field: 'gridData.Equivalent.Weight',
        title: 'Weight (%)',
        width: 80,
        type: 'percent',
        sortable: true,
        description: 'Weight of the position in the portfolio.',
      },
      price: {
        title: 'Mark Price',
        field: 'gridData.MarkPrice',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'gridData.MarkPriceCurrency' satisfies Leaves<PortfolioRiskDataItem>,
        },
        description: 'Most recent mark price for the instrument provided by the venue.',
      },

      position: {
        id: 'Position',
        field: 'gridData.Position',
        title: 'Position Qty',
        type: 'size',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'gridData.Asset' satisfies Leaves<PortfolioRiskDataItem>,
          trimTrailingZeroes: true,
          showCurrency: false,
        },
        sortable: true,
        description: 'Position normalized in asset currency.',
      },
      positionHome: {
        type: 'size',
        field: 'gridData.Equivalent.Position',
        title: `Position (${homeCurrency})`,
        params: {
          currencyField: 'gridData.Equivalent.Currency' satisfies Leaves<PortfolioRiskDataItem>,
          highlightNegative: true,
        },
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      riskValuationTime: {
        field: 'gridData.RiskValuationTime',
        title: 'Risk Valuation Time',
        type: 'date',
      },
      inspectorButton: {
        sortable: false,
        type: 'iconButton',
        id: 'inspector-button-column',
        suppressColumnsToolPanel: true,
        frozen: true,
        pinned: 'right',
        width: 45,
        params: {
          icon: IconName.Deepdive,
          onClick: params => {
            const data = params.node.data;
            if (isPortfolioRiskInspectorRow(data)) {
              onInspectorOpen(data);
            }
          },
          hide: params => {
            return !isPortfolioRiskInspectorRow(params.node.data);
          },
        } satisfies AgGridIconButtonProps<PortfolioRiskDataItem>,
      },
    } as const satisfies Record<string, ColumnDef<PortfolioRiskDataItem>>;

    const colDefsMap = Object.entries(mapping).reduce((result, [key, value]) => {
      result.set(key, value);
      return result;
    }, new Map<string, Column>());

    return {
      mapping,
      colDefsMap,
    };
  }, [homeCurrency, onInspectorOpen, rollupTreeRef]);
}
