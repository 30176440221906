import type { TreeRow } from '@talos/kyoko';
import type { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { getPortfolioRiskHierarchyFormattedValue } from '../components/PortfolioRiskBlotter/useRollupTreeGridBuilders';
import type { BasePortfolioManagementDataItem } from './BasePortfolioManagementDataItem';
import type { PortfolioRiskGridData } from './PortfolioRiskGridData';
import type { PortfolioRiskSubscriptionData } from './PortfolioRiskSubscriptionData';

export class PortfolioRiskDataItem implements BasePortfolioManagementDataItem, TreeRow {
  dataPath: string[];
  skipAggregation: boolean;
  rowID: string;

  riskData: PortfolioRiskSubscriptionData;
  gridData: PortfolioRiskGridData;

  groupColumnValueGetter(params: ValueGetterParams<PortfolioRiskDataItem>): string {
    if (!params.node) {
      return '';
    }

    return getPortfolioRiskHierarchyFormattedValue(params.node, params.context.current);
  }

  groupColumnValueFormatter({ value, context }: ValueFormatterParams<PortfolioRiskDataItem, string>): string {
    return value ?? '';
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  constructor(gridData: PortfolioRiskGridData, riskData: PortfolioRiskSubscriptionData) {
    this.dataPath = gridData.dataPath;
    this.skipAggregation = gridData.skipAggregation;
    this.rowID = gridData.rowID;

    this.riskData = riskData;
    this.gridData = gridData;
  }
}
