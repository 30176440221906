import {
  Divider,
  Flex,
  HStack,
  Meter,
  type MeterWithValueProps,
  MixpanelEvent,
  type Order,
  SmartSummary,
  Text,
  useMixpanel,
} from '@talos/kyoko';
import { useEffect } from 'react';
import { useFeatureFlag } from '../../hooks';
import { DDHIcon } from './DDHIcon';
import { DDHSummary } from './DDHSummary';

interface DDHProgressProps {
  order: Order;
  meterProps: MeterWithValueProps;
}

export const DDHProgress = ({ order, meterProps }: DDHProgressProps) => {
  const mixpanel = useMixpanel();
  const { enableDynamicDeltaHedging } = useFeatureFlag();

  useEffect(() => {
    if (enableDynamicDeltaHedging && order.HedgeOrderStatus) {
      mixpanel.track(MixpanelEvent.ViewDynamicDeltaHedgingTooltip);
    }
  }, [mixpanel, enableDynamicDeltaHedging, order.HedgeOrderStatus]);

  if (!enableDynamicDeltaHedging || !order.HedgeOrderStatus) {
    return null;
  }

  return (
    <Flex flexDirection="column" gap="spacingDefault" w="300px" data-testid="ddh-progress">
      <Text textTransform="uppercase" color="colorTextSubtle">
        Parent Order
      </Text>
      <SmartSummary entity={order} type="order" />
      <Meter {...meterProps} />

      <Divider />
      <HStack gap="spacingSmall" justifyContent="flex-start">
        <Text color="colorTextSubtle">DDH Summary</Text>
        <DDHIcon />
      </HStack>

      <DDHSummary order={order} usingMarketDataStatistics={false} />
    </Flex>
  );
};
