import { AGGRID_AUTOCOLUMN_ID, baseTreeGroupColumnDef, getTreeRowBlotterGroupColDef, type TreeRow } from '@talos/kyoko';
import { autoGroupSortingDefaults } from '@talos/kyoko/src/components/BlotterTable/columns/autoGroupSortingDefaults';
import type { GetDataPath, GridOptions, GroupCellRendererParams, IRowNode } from 'ag-grid-community';
import type { AgGridContext } from 'ag-grid-community/dist/types/core/interfaces/iCommon';
import { useMemo } from 'react';
import type { BasePortfolioManagementDataItem } from '../../types/BasePortfolioManagementDataItem';
import { ASSET_GROUP_PREFIX, BOOK_GROUP_PREFIX, ROLLUP_GROUP_PREFIX, UNDERLYING_GROUP_PREFIX } from '../../types/types';
import { useRollupTreeAutoGroupCellSelector } from './useRollupTreeAutoGroupCellSelector';

interface UseRollupTreeGridBuildersOutput {
  blotterParams: Pick<GridOptions, 'getDataPath' | 'autoGroupColumnDef' | 'showOpenedGroup' | 'treeData'>;
}

/** Properties created client side for row uniqueness and grouping */
export interface AggSkippableTreeRowData extends Pick<TreeRow, 'dataPath' | 'rowID'> {
  /** skip agg (most likely due to duplicate data entries) */
  skipAggregation: boolean;
}

const getDataPath: GetDataPath = <TData extends TreeRow>(node: TData) => {
  return node.dataPath;
};

// GroupCellRenderer InnerRenderer supports framework components though the type is not updated in the ag-grid types
// Newer ag-grid versions use IGroupCellRendererParams: https://www.ag-grid.com/javascript-data-grid/group-cell-renderer/
export type GroupCellRendererParamsCustom = Pick<
  Partial<GroupCellRendererParams>,
  'suppressCount' | 'suppressDoubleClickExpand' | 'checkbox' | 'innerRendererParams'
> & {
  innerRenderer: React.FC<any>;
};

const getSecurityValue = (
  symbol: string,
  contextGetters: Pick<AgGridContext['current'], 'currenciesBySymbol' | 'securitiesBySymbol'>
) => {
  return (
    contextGetters.currenciesBySymbol?.get(symbol)?.Symbol ??
    contextGetters.securitiesBySymbol?.get(symbol)?.DisplaySymbol ??
    symbol
  );
};

export function getPortfolioRiskHierarchyFormattedValue(
  node: IRowNode<BasePortfolioManagementDataItem>,
  contextGetters: Pick<AgGridContext['current'], 'currenciesBySymbol' | 'securitiesBySymbol'>
): string {
  const { groupData, data } = node;
  const groupValue = groupData?.[AGGRID_AUTOCOLUMN_ID];
  if (typeof groupValue !== 'string') {
    throw new Error('Missing groupData group value: ', {
      cause: node,
    });
  }
  const groupValueArray = groupValue.split('::');
  const groupPrefix = groupData ? groupValueArray[0] + '::' : '';
  let dataValue = '';
  switch (groupPrefix) {
    case ROLLUP_GROUP_PREFIX:
    case BOOK_GROUP_PREFIX:
      dataValue = groupValueArray[1] ?? '';
      break;
    case UNDERLYING_GROUP_PREFIX:
      dataValue = getSecurityValue(groupValueArray[1] ?? '', contextGetters);
      break;
    case ASSET_GROUP_PREFIX:
      dataValue = getSecurityValue(data?.riskData.Asset ?? '', contextGetters);
      break;
    default: {
      throw new Error('Unexpected group prefix');
    }
  }
  return dataValue;
}

/** Get AgGrid requirements for constructing the Hierarchical Rollup->SubAccount->Position Grids  */
export const useRollupTreeGridBuilders = (): UseRollupTreeGridBuildersOutput => {
  const subAccountCellSelector = useRollupTreeAutoGroupCellSelector();
  const autoGroupColumnDef = useMemo(
    (): GridOptions['autoGroupColumnDef'] => ({
      ...baseTreeGroupColumnDef,
      headerName: 'Rollup/SubAccount - Asset',
      pinned: 'left',
      width: 300,
      ...autoGroupSortingDefaults,
      ...getTreeRowBlotterGroupColDef({
        nodeValueGetter: ({ node, context }) => {
          if (!node) {
            return undefined;
          }
          return getPortfolioRiskHierarchyFormattedValue(node, context.current);
        },
        nodeValueFormatter: ({ value }) => {
          return value;
        },
        nodeFilterValueGetter: ({ node, context }) => {
          if (!node) {
            return undefined;
          }
          return getPortfolioRiskHierarchyFormattedValue(node, context.current);
        },
      }),
      ...subAccountCellSelector,
    }),
    [subAccountCellSelector]
  );

  const output: UseRollupTreeGridBuildersOutput = useMemo(
    () => ({
      blotterParams: {
        getDataPath,
        autoGroupColumnDef,
        showOpenedGroup: true,
        treeData: true,
      },
    }),
    [autoGroupColumnDef]
  );

  return output;
};
