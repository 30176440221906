import {
  BALANCE_DELTA,
  POSITION,
  Position,
  PositionUpdateSourceEnum,
  ProductTypeEnum,
  useMarketAccountsContext,
  useSubscription,
  useSyncedRef,
  wsMerge,
  type MinimalSubscriptionResponse,
  type WebsocketRequest,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { map, tap, type Observable } from 'rxjs';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import type { Balance } from '../../../../types';
import { useEnrichDeltaBalancesPipe } from '../../BalancesV2/useEnrichedDeltaBalancesObs';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../tokens';
import type { PositionsTableFilter } from '../types';

interface PositionsRequest extends WebsocketRequest, PositionsTableFilter {
  ShowZeroBalances: boolean;
  EquivalentCurrency: string;
  Tolerance: string;
}

interface BalanceRequest extends WebsocketRequest, PositionsTableFilter {
  ShowZeroBalances: boolean;
  EquivalentCurrency: string;
}

interface UseUnifiedPositionsBalancesObsParams {
  tag: string;
  showZeroBalances: boolean;
  filter: PositionsTableFilter;
}

/**
 * This hook makes two requests -- one for BalanceDelta, one for Position -- and then merges these
 * together into one outgoing stream of type Position. BalanceDelta messages are converted to "synthetic" Positions.
 */
export const useUnifiedPositionsBalancesObs = ({
  tag,
  showZeroBalances,
  filter,
}: UseUnifiedPositionsBalancesObsParams) => {
  const { homeCurrency } = useDisplaySettings();
  const { marketAccountsByID, marketAccountsByName } = useMarketAccountsContext();
  const marketAccountsByIDRef = useSyncedRef(marketAccountsByID);
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);

  const [positionsRequest, setPositionsRequest] = useState<PositionsRequest>({
    name: POSITION,
    tag: tag,
    ShowZeroBalances: showZeroBalances,
    EquivalentCurrency: homeCurrency,
    Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
    ...onlyServerKeys(filter),
  });

  useEffect(() => {
    setPositionsRequest(currentRequest => {
      const maybeNewRequest: PositionsRequest = {
        name: POSITION,
        tag: tag,
        ShowZeroBalances: showZeroBalances,
        EquivalentCurrency: homeCurrency,
        Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
        ...onlyServerKeys(filter),
      };

      return isEqual(currentRequest, maybeNewRequest) ? currentRequest : maybeNewRequest;
    });
  }, [tag, showZeroBalances, homeCurrency, filter]);

  const [balancesRequest, setBalancesRequest] = useState<BalanceRequest>({
    name: BALANCE_DELTA,
    tag: tag,
    ShowZeroBalances: showZeroBalances,
    EquivalentCurrency: homeCurrency,
    ...onlyServerKeys(filter),
  });

  useEffect(() => {
    setBalancesRequest(currentRequest => {
      const maybeNewRequest: BalanceRequest = {
        name: BALANCE_DELTA,
        tag: tag,
        ShowZeroBalances: showZeroBalances,
        EquivalentCurrency: homeCurrency,
        ...onlyServerKeys(filter),
      };

      return isEqual(currentRequest, maybeNewRequest) ? currentRequest : maybeNewRequest;
    });
  }, [tag, showZeroBalances, homeCurrency, filter]);

  const { data: positionsObs } = useSubscription<Position>(positionsRequest);

  // Enriches with MarketAccount.Group and MarketAccount.Market
  const enrichedPositionsObs = useMemo(
    () =>
      positionsObs.pipe(
        tap(message => {
          message.data.forEach(position => {
            const mktAcc = marketAccountsByNameRef.current.get(position.MarketAccount);
            position.marketAccountGroup = mktAcc?.Group;
            position.market = mktAcc?.Market;
          });
        })
      ),
    [positionsObs, marketAccountsByNameRef]
  );

  const { data: balancesObs } = useSubscription<Balance>(balancesRequest);

  const enrichBalancesPipe = useEnrichDeltaBalancesPipe();
  const enrichedBalancesObs = useMemo(() => balancesObs.pipe(enrichBalancesPipe), [balancesObs, enrichBalancesPipe]);

  // Need to turn received BalanceDelta into Position
  const balancesMappedToPositionsObs: Observable<MinimalSubscriptionResponse<Position, string>> = useMemo(
    () =>
      enrichedBalancesObs.pipe(
        map(message => {
          const newData: Position[] = [];
          for (const balance of message.data) {
            const mktAcc = marketAccountsByIDRef.current.get(balance.MarketAccountID);
            if (!mktAcc) {
              continue;
            }

            const maybeEquivalent: Position['Equivalent'] = balance.Equivalent
              ? {
                  Amount: balance.Equivalent.Amount,
                  Currency: balance.Equivalent.Currency,
                  OutstandingBuy: balance.Equivalent.OutstandingBuy,
                  OutstandingSell: balance.Equivalent.OutstandingSell,
                  Delta: balance.Equivalent.Amount,
                  InitialMargin: balance.Equivalent.InitialMargin,
                  MaintenanceMargin: balance.Equivalent.MaintenanceMargin,
                }
              : undefined;

            newData.push(
              new Position({
                Amount: balance.Amount,
                AssetType: ProductTypeEnum.Spot,
                Asset: balance.Currency,
                MarketAccount: mktAcc.Name,
                LastUpdateTime: balance.LastUpdateTime,
                Status: balance.Status,
                PositionSource: PositionUpdateSourceEnum.Gateway,
                Equivalent: maybeEquivalent,
                marketAccountGroup: balance.marketAccountGroup,
                OutstandingBuy: balance.OutstandingBuy,
                OutstandingSell: balance.OutstandingSell,
                Delta: '1',
                market: mktAcc.Market,
              })
            );
          }

          return {
            ...message,
            data: newData,
          };
        })
      ),
    [enrichedBalancesObs, marketAccountsByIDRef]
  );

  const unifiedObs = useMemo(
    () => wsMerge({ sources: [enrichedPositionsObs, balancesMappedToPositionsObs], getUniqueKey: item => item.rowID }),
    [enrichedPositionsObs, balancesMappedToPositionsObs]
  );

  return unifiedObs;
};

function onlyServerKeys(filter: PositionsTableFilter | undefined): PositionsTableFilter {
  if (filter == null) {
    return {};
  }

  // Omit expiries and mkt acc groups, theyre frontend
  return { ...filter, Expiries: undefined, MarketAccountGroups: undefined };
}
