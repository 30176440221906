import { BLOTTER_TABLE_FILTERS_CONTAINER_ID, Box, HStack, usePortal, VStack, type BoxProps } from '@talos/kyoko';
import { ScrollableBox } from '@talos/kyoko/src/components/ScrollableBox';
import type { PropsWithChildren } from 'react';

const overflowOuterProps: BoxProps = { h: '100%' };
const overflowInnerProps: BoxProps = { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' };

export const BlotterContainer = ({ children }: PropsWithChildren) => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack h="40px" justifyContent="right" gap="spacingComfortable" background="backgroundContent">
        {/* Allow the filter container to scroll so the drawer handles well*/}
        <ScrollableBox outerProps={overflowOuterProps} innerProps={overflowInnerProps}>
          <Box ref={filtersContainerRef} pr="spacingComfortable" />
        </ScrollableBox>
      </HStack>
      <Box flex="1">{children}</Box>
    </VStack>
  );
};
