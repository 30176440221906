import type {
  ColDef,
  GroupCellRendererParams,
  ICellRendererFunc,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { GroupCellRenderer } from 'ag-grid-enterprise';
import { Text } from '../../Text';

/**
 * The TreeRow is an abstraction to help you write better Tree Blotters. It helps with the mess around having different types of entities
 * on different levels of the blotter. In this approach, we put distinct valueGetters and friends on each of the rows. Entities extending TreeRow
 * are then used together with the `getTreeRowBlotterGroupColDef` default which tells the blotter how to consume rows of type TreeRow.
 */
export interface TreeRow {
  dataPath: string[];
  rowID: string;

  /** Your cell's valueGetter. */
  groupColumnValueGetter: (params: ValueGetterParams) => unknown;
  /** Your cell's value formatted getter. */
  groupColumnValueFormatter: (params: ValueFormatterParams) => string;
  /**
   * Your cell's filter value getter. Invoked when for example right-clicking on a cell to get the filterable value of the cell.
   * If this is just the output of your valueGetter (it usually is), you can point this function at that one.
   */
  groupColumnFilterValueGetter: (params: ValueGetterParams) => unknown;
  /**
   * The Cell Renderer of this cell. If left undefined, you'll get a basic text cell renderer from AgGrid.
   *
   * Can be implemented to either return a string or a ReactNode. You can also invoke the cellRenderer of existing standard columns
   * and return that.
   */
  groupColumnInnerCellRenderer?: ICellRendererFunc;
}

/**
 * This const should be spread onto your group col def for tree blotters which makes use of the TreeRow abstraction
 *
 * The const defines the methods that tell AgGrid how to interact with nodes of type TreeRow
 *
 * Note: This was made to be a function to be able to do <TData extends TreeRow>. Otherwise it'd just be <TreeRow>.
 */
export const getTreeRowBlotterGroupColDef = <TData extends TreeRow>(getterOverrides?: {
  nodeValueGetter?: Exclude<ColDef<TData>['valueGetter'], string>;
  nodeValueFormatter?: Exclude<ColDef<TData>['valueFormatter'], string>;
  nodeFilterValueGetter?: Exclude<ColDef<TData>['filterValueGetter'], string>;
}): Pick<ColDef<TData>, 'valueGetter' | 'valueFormatter' | 'filterValueGetter' | 'cellRendererSelector'> => {
  const { nodeValueGetter, nodeValueFormatter, nodeFilterValueGetter } = getterOverrides ?? {};
  return {
    valueGetter: params => {
      const data = params.node?.data;
      if (!data) {
        return nodeValueGetter?.(params);
      }

      return data.groupColumnValueGetter(params);
    },
    valueFormatter: params => {
      const data = params.node?.data;
      if (!data) {
        return nodeValueFormatter?.(params) ?? '';
      }

      return data.groupColumnValueFormatter(params);
    },
    filterValueGetter: params => {
      const data = params.node?.data;
      if (!data) {
        return nodeFilterValueGetter?.(params);
      }

      return data.groupColumnFilterValueGetter(params);
    },

    cellRendererSelector: params => {
      // If its a pinned row (the totals row in this case), we opt out of the default group cell renderer and just do basic text to show "Totals"
      // Bit of a hard-coded case where we assume that .data has "groupColumnValue". This is set by the totals aggregation pipe separately.
      if (params.node.rowPinned) {
        return {
          params,
          component: (params: GroupCellRendererParams) => <Text>{params.data.groupColumnValue ?? params.value}</Text>,
        };
      }

      // Else if the node has defined a way to resolve its own cell renderer to be used, then we use that.
      // If not, just do the basic GroupCellRenderer setup
      const innerRenderer = params.node.data?.groupColumnInnerCellRenderer;
      if (innerRenderer) {
        return {
          component: GroupCellRenderer,
          params: {
            ...params,
            innerRenderer,
          },
        };
      }

      return { params, component: GroupCellRenderer };
    },
  };
};
