import { Position, ProductTypeEnum, toBigWithDefault } from '@talos/kyoko';
import Big from 'big.js';

export type OperationsOverviewShowBy = 'Market' | 'MarketAccount' | 'Asset';

export interface OpsOverviewFilter {
  Symbols?: string[];
}

export const SHOW_BY_DICT = {
  Market: { showBy: 'Market', label: 'By Market', key: 'market' },
  MarketAccount: { showBy: 'MarketAccount', label: 'By Market Account', key: 'MarketAccount' },
  Asset: { showBy: 'Asset', label: 'By Underlying', key: 'underlying' },
} as const satisfies {
  [key in OperationsOverviewShowBy]: { showBy: key; label: string; key: keyof OpsPosition };
};

export class OpsPosition extends Position {
  underlying: string;
  marketAccountTotal: Big;

  get shareOfMarketAccountTotal(): Big | undefined {
    if (this.marketAccountTotal.eq(0)) {
      return undefined;
    }

    const positionBig = toBigWithDefault(this.Equivalent?.Amount, 0);
    if (positionBig.eq(0)) {
      return Big(0);
    }

    return positionBig.div(this.marketAccountTotal);
  }

  get balance() {
    return this.AssetType === ProductTypeEnum.Spot ? this.Amount : undefined;
  }

  get balanceEquivalent() {
    return this.AssetType === ProductTypeEnum.Spot ? this.Equivalent?.Amount : undefined;
  }

  get equityEquivalent() {
    if (this.AssetType === ProductTypeEnum.Spot) {
      return this.Equivalent?.Amount;
    } else {
      return this.Equivalent?.UnrealizedPnL;
    }
  }

  constructor(position: Position, { underlying, marketAccountTotal }: { underlying: string; marketAccountTotal: Big }) {
    super(position);
    this.underlying = underlying;
    this.marketAccountTotal = marketAccountTotal;
  }
}
