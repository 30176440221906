import { asset, type AssetColumnProps } from '@talos/kyoko/src/components/BlotterTable/columns/columns';
import { subAccountName } from '@talos/kyoko/src/components/BlotterTable/columns/subAccountName';
import type { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { GroupCellRenderer } from 'ag-grid-enterprise';
import { useRollupTreeRef } from 'hooks';
import { useMemo } from 'react';
import type { PortfolioManagementBlotterDataItem } from '../../types/PortfolioManagementBlotterDataItem';
import type { PortfolioRiskGridData } from '../../types/PortfolioRiskGridData';
import { ASSET_GROUP_PREFIX, BOOK_GROUP_PREFIX, ROLLUP_GROUP_PREFIX, UNDERLYING_GROUP_PREFIX } from '../../types/types';
import type { GroupCellRendererParamsCustom } from './useRollupTreeGridBuilders';

const assetComponent = asset({
  id: 'Asset',
  field: 'Asset',
  type: 'asset',
  pinned: 'left',
  sortable: true,
  width: 150,
  title: 'Asset',
  params: {
    assetTypeField: 'gridData.AssetType',
    colorful: true,
  } satisfies AssetColumnProps<PortfolioManagementBlotterDataItem>,
});

type UseRollupAutoGroupCellSelectorOutput = Pick<
  NonNullable<GridOptions['autoGroupColumnDef']>,
  'cellRendererSelector'
>;

/** The Rollup tree for Ag-Grid group colors should change based on the data level (from Rollup(s)
 * to Book to Underlying to Asset.  The auto-grouping selector drives this mechanism (Across multiple grids)
 * by analyzing the hierarchy in the tree.
 *
 * This allows us to use a simple array based data path, and then dynamically render the group level) */
export function useRollupTreeAutoGroupCellSelector() {
  const rollupTreeRef = useRollupTreeRef();
  const subAccountCol = useMemo(() => {
    return subAccountName({
      id: 'SubAccount',
      field: 'SubAccount',
      type: 'subAccountName',
      sortable: true,
      hide: true,
      params: {
        rollupTree: rollupTreeRef,
      },
    });
  }, [rollupTreeRef]);
  const autoGroupColDefOverride = useMemo<UseRollupAutoGroupCellSelectorOutput>(() => {
    const assetMatcher = new RegExp(`^(${UNDERLYING_GROUP_PREFIX}|${ASSET_GROUP_PREFIX})`);
    const subAccountMatcher = new RegExp(`^(${BOOK_GROUP_PREFIX}|${ROLLUP_GROUP_PREFIX})`);
    return {
      // Drive the GroupCell renderer based on the matchers above
      // SubAccounts (Books and Rollup levels) both use the subAccount cell renderer
      // Assets (Underlying and Asset levels) both use the asset cell renderer
      cellRendererSelector: (params: ICellRendererParams<PortfolioRiskGridData>) => {
        return {
          component: GroupCellRenderer,
          params: {
            suppressCount: true,
            innerRenderer: (params: ICellRendererParams<PortfolioRiskGridData>) => {
              if (params.node.key?.match(new RegExp(assetMatcher))) {
                const asset = params.node.key.replace(new RegExp(assetMatcher), '');
                return assetComponent.cellRenderer({
                  ...params,
                  value: asset,
                });
              } else if (params.node.key?.match(subAccountMatcher)) {
                const subAccount = params.node.key.replace(subAccountMatcher, '');
                return subAccountCol.cellRenderer({
                  ...params,
                  value: subAccount,
                });
              } else {
                throw new Error(`Unexpected row key ${params.node.key}`);
              }
            },
          } satisfies GroupCellRendererParamsCustom,
        };
      },
    };
  }, [subAccountCol]);

  return autoGroupColDefOverride;
}
