import {
  Box,
  ExpandableBottomPanel,
  HStack,
  Tab,
  TabAppearance,
  TabList,
  Tabs,
  TabSize,
  toBigWithDefault,
  useExpandablePanel,
  useMarketAccountsContext,
  useObservableValue,
  usePortal,
  useSyncedRef,
  useTabs,
  VStack,
  wsScanToMap,
} from '@talos/kyoko';
import { useCallback, useState } from 'react';
import { map, shareReplay } from 'rxjs';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { type Balance, getBalanceKey } from '../../../../types';
import { Module } from '../../components/Module';
import { BlotterWrapper, XScrollableContainer } from '../../styles';
import { TreasuryManagementBlotterContainer } from '../Blotter';
import { OperationsPie } from '../Chart/OperationsPie';
import { HistoricalValueChart } from '../HistoricalValueChart';
import { useTreasuryManagementBalances } from '../providers/TreasuryManagementBalancesProvider';
import { useTreasuryManagementInteractions } from '../providers/TreasuryManagementInteractionsProvider';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';
import { CHART_HEADER_SUFFIX_PORTAL_ID } from '../types';

const TABS = [
  {
    label: 'Treasury',
    render: () => <TreasuryManagementBlotterContainer />,
  },
];

const DEFAULT_BLOTTER_HEIGHT = 450;
const TREASURY_MANAGEMENT_BLOTTER_HEIGHT = 'TreasuryManagementBlotterHeight';

export function BottomTabContent() {
  const [isBlotterMaximized, setIsBlotterMaximized] = useState(false);
  const [isBlotterMinimized, setIsBlotterMinimized] = useState(false);

  const handleMaximizeBlotter = useCallback((shouldExpand: boolean) => {
    setIsBlotterMinimized(false);
    setIsBlotterMaximized(curr => shouldExpand ?? !curr);
  }, []);

  const handleMinimizeBlotter = useCallback((shouldMinimize: boolean) => {
    setIsBlotterMaximized(false);
    setIsBlotterMinimized(curr => shouldMinimize ?? !curr);
  }, []);

  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: TABS,
  });

  const { containerRef, ...panelProps } = useExpandablePanel<HTMLDivElement>({
    initialHeight: parseInt(localStorage.getItem(TREASURY_MANAGEMENT_BLOTTER_HEIGHT) ?? '0') || DEFAULT_BLOTTER_HEIGHT,
    isExpanded: isBlotterMaximized,
    isMinimized: isBlotterMinimized,
    onToggleExpanded: handleMaximizeBlotter,
    onToggleMinimize: handleMinimizeBlotter,
    onAdjustedHeight(newHeight) {
      localStorage.setItem(TREASURY_MANAGEMENT_BLOTTER_HEIGHT, newHeight.toString());
    },
  });

  const {
    state: { showBy },
  } = useTreasuryManagementContext();

  const { setPortalRef: chartHeaderSuffixRef } = usePortal(CHART_HEADER_SUFFIX_PORTAL_ID);

  const { balancesObs } = useTreasuryManagementBalances();
  const balances = useObservableValue(
    () =>
      balancesObs.pipe(
        wsScanToMap({ getUniqueKey: getBalanceKey, newMapEachUpdate: false }),
        map(map => [...map.values()]),
        shareReplay({ bufferSize: 1, refCount: true })
      ),
    [balancesObs]
  );

  const { goToGroupRow } = useTreasuryManagementInteractions();
  const { state } = useTreasuryManagementContext();
  const handleSliceClick = useCallback(
    (key: string) => {
      goToGroupRow?.(key);
    },
    [goToGroupRow]
  );

  const { marketAccountsByID } = useMarketAccountsContext();
  const marketAccountsByIDRef = useSyncedRef(marketAccountsByID);

  const getBalanceValue = useCallback((balance: Balance) => toBigWithDefault(balance.Equivalent?.Amount, 0), []);
  const getBalanceGroupBy = useCallback(
    (balance: Balance) => {
      return showBy === 'asset' ? balance.Currency : balance.marketAccountName;
    },
    [showBy]
  );
  const getBalanceLabel = useCallback(
    (balance: Balance) => {
      if (showBy === 'asset') {
        return balance.Currency;
      }

      const mktAcc = marketAccountsByIDRef.current.get(balance.MarketAccountID);
      return mktAcc?.DisplayName ?? mktAcc?.Name ?? balance.MarketAccountID.toString();
    },
    [marketAccountsByIDRef, showBy]
  );

  return (
    <VStack w="100%" h="100%" overflow="hidden" position="relative" ref={containerRef}>
      <XScrollableContainer w="100%">
        <HStack minHeight="400px" w="100%" h="100%" minWidth="1000px" gap="spacingTiny">
          <Module
            flex="1 1 45%"
            h="100%"
            minWidth="450px"
            maxWidth="650px"
            title={`Spot Balances by ${showBy === 'counterparty' ? 'Counterparty' : 'Asset'}`}
          >
            <OperationsPie
              entities={balances}
              // map our showBy variations to the ones that OperationsPie accepts
              showBy={showBy === 'counterparty' ? 'market' : 'asset'}
              onSliceClick={handleSliceClick}
              asOf={state.snapshotDate}
              getValue={getBalanceValue}
              getGroupBy={getBalanceGroupBy}
              getLabel={getBalanceLabel}
              centerLabel="Total Portfolio"
              showing="balances"
            />
          </Module>
          <Module flex="1 10 55%" h="100%" title="Historical Balances" suffix={<Box ref={chartHeaderSuffixRef} />}>
            {/* The 50px right padding is here to emulate the non-deterministic left "spacing" of the pie chart*/}
            <HistoricalValueChart maxHeight="600px" py="spacingComfortable" px="spacingLarge" />
          </Module>
        </HStack>
      </XScrollableContainer>
      <BlotterWrapper>
        <ExpandableBottomPanel
          {...panelProps}
          showControls
          header={
            <Tabs {...tabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
              <TabList>
                {TABS.map((tab, i) => (
                  <Tab key={i} {...tab} />
                ))}
              </TabList>
            </Tabs>
          }
        >
          {TABS.map(
            (tab, i) => tabs.selectedIndex === i && <ErrorBoundary key={`component-${i}`}>{tab.render()}</ErrorBoundary>
          )}
        </ExpandableBottomPanel>
      </BlotterWrapper>
    </VStack>
  );
}
