import {
  Button,
  DrawerFooter,
  FormControlSizes,
  IconButton,
  IconName,
  Text,
  VStack,
  type BoxProps,
} from '@talos/kyoko';
import { DrawerHeaderFlex } from '@talos/kyoko/src/components/Drawer/styles';
import { ScrollableBox } from '@talos/kyoko/src/components/ScrollableBox';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { useCallback } from 'react';
import styled from 'styled-components';
import { InspectorDataSections } from './InspectorDataSections';
import type { InspectorDrawerProps } from './InspectorDrawer';
import { InspectorBasicInfo } from './InspectorPositionBasicInfo';

export const overflowOuterProps: BoxProps = { h: '100%', overflow: 'auto' };
export const overflowInnerProps: BoxProps = {
  m: 'spacingMedium',
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const InspectorDrawerContent = ({ dataItem, ...drawerProps }: InspectorDrawerProps) => {
  const { close } = drawerProps;

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  if (!dataItem) {
    return null;
  }

  return (
    <ErrorBoundary>
      <ContentWrapper data-testid="inspector-drawer">
        <DrawerHeaderFlex>
          <Text color="colorTextImportant">Instrument Details</Text>
          <IconButton
            data-testid="header-close"
            size={FormControlSizes.Small}
            icon={IconName.Close}
            onClick={handleClose}
          />
        </DrawerHeaderFlex>

        <InspectorBasicInfo dataItem={dataItem} />
        <ScrollableBox outerProps={overflowOuterProps} innerProps={overflowInnerProps}>
          <VStack gap="spacingComfortable" w="100%" alignItems="stretch">
            <InspectorDataSections dataItem={dataItem} />
          </VStack>
        </ScrollableBox>

        <DrawerFooter w="100%">
          <Button onClick={handleClose}>Close</Button>
        </DrawerFooter>
      </ContentWrapper>
    </ErrorBoundary>
  );
};
