import { MixpanelEvent } from '@talos/kyoko';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { TradingSettingCheckbox } from './TradingSettingCheckbox';

export function MarketCardBBOCheckbox() {
  const { alwaysShowMarketCardBestBidOffer, setAlwaysShowMarketCardBestBidOffer } = useTradingSettings();

  return (
    <TradingSettingCheckbox
      id="marketCardBestBidOffer"
      isEnabled={alwaysShowMarketCardBestBidOffer}
      onChecked={setAlwaysShowMarketCardBestBidOffer}
      mixPanelEvent={MixpanelEvent.ToggleAlwaysDisplayMarketCardBestBidOffer}
      displayName="Default Always Show BBO setting on new Market Cards to On"
      displayDescription="When enabled, the Always Show Best Bid Offer setting on market cards will default to On, when creating new cards."
    />
  );
}
