import { createSlice } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

export const portfolioManagementPositionRequestSlice = createSlice({
  name: 'portfolioManagementPositionRequest',
  initialState: {
    tagColumns: undefined,
  },
  reducers: {
    setColumnsForTags: (state, action) => {
      state.tagColumns = castDraft(action.payload);
    },
  },
});
