import type { AgGridPriceProps, ColumnDef, SizeColumnParams } from '@talos/kyoko';
import { useMemo } from 'react';
import { useFeatureFlag } from '../../../../hooks';
import type { PortfolioManagementBlotterDataItem } from '../types/PortfolioManagementBlotterDataItem';

type CommonPortfolioGridData = PortfolioManagementBlotterDataItem;

function getPnlTagColumns(isPerformanceGrid: boolean, homeCurrency: string) {
  return {
    avgCost: isPerformanceGrid
      ? {
          title: 'Avg Cost',
          field: 'position.AvgCost',
          type: 'price',
          width: 150,
          params: {
            quoteCurrencyField: 'position.AvgCostCurrency',
          } satisfies AgGridPriceProps<CommonPortfolioGridData>,
          description: 'Average cost for the instrument.',
        }
      : undefined,
    avgCostHome: isPerformanceGrid
      ? {
          title: `Avg Cost (${homeCurrency})`,
          field: 'position.Equivalent.AvgCost',
          type: 'price',
          width: 150,
          hide: true,
          params: {
            quoteCurrencyField: 'gridData.Equivalent.Currency',
          } satisfies AgGridPriceProps<CommonPortfolioGridData>,
          description: "Average cost for the instrument, converted to the user's home currency.",
        }
      : undefined,
    priceHome: isPerformanceGrid
      ? {
          type: 'size',
          field: 'position.Equivalent.MarkPrice',
          params: {
            currencyField: 'gridData.Equivalent.Currency',
          } satisfies SizeColumnParams<CommonPortfolioGridData>,
          hide: true,
          title: { intlKey: 'markPriceHomeCurrency' },
          titleIntlKeyValues: { homeCurrency },
          aggregate: true,
          description: "Most recent mark price for the instrument, converted to the user's home currency.",
        }
      : undefined,
    eodPriceMark: isPerformanceGrid
      ? {
          field: 'position.PnLTags.EndOfDay.OpeningPriceMark',
          type: 'price',
          title: 'EOD Mark Price',
          params: {
            quoteCurrencyField: 'gridData.MarkPriceCurrency',
          } satisfies AgGridPriceProps<CommonPortfolioGridData>,
          description: 'End of day price mark.',
        }
      : undefined,
    eodPriceMarkHome: isPerformanceGrid
      ? {
          field: 'position.PnLTags.EndOfDay.Equivalent.OpeningPriceMark',
          type: 'price',
          title: `EOD Mark Price (${homeCurrency})`,
          hide: true,
          params: {
            quoteCurrencyField: 'gridData.Equivalent.Currency',
          } satisfies AgGridPriceProps<CommonPortfolioGridData>,
          description: `End of day price mark, converted to the user's home currency.`,
        }
      : undefined,
    eodMarkTime: isPerformanceGrid
      ? {
          field: 'position.PnLTags.EndOfDay.OpenTimestamp',
          type: 'date',
          title: 'EOD Mark Time',
          hide: true,
          description: 'End of day price mark timestamp.',
        }
      : undefined,
    eodPosition: {
      field: 'position.PnLTags.EndOfDay.OpeningPosition',
      type: 'size',
      title: 'EOD Position',
      aggregate: true,
      params: {
        currencyField: 'gridData.Asset',
        highlightNegative: true,
        showCurrencyForNullPosition: false,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description: 'Notional position as of EOD timestamp.',
    },
    eodPositionHome: {
      field: 'position.PnLTags.EndOfDay.Equivalent.OpeningPosition',
      type: 'size',
      title: `EOD Position (${homeCurrency})`,
      aggregate: true,
      hide: true,
      params: {
        currencyField: 'gridData.Equivalent.Currency',
        highlightNegative: true,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description: "Notional position as of EOD timestamp, converted to the user's home currency",
    },
    eodUnrealizedPnl: {
      field: 'position.PnLTags.EndOfDay.OpeningUnrealizedPnL',
      type: 'size',
      title: 'EOD Position PnL',
      aggregate: true,
      params: {
        currencyField: 'position.PnLCurrency',
        highlightNegative: true,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description:
        'PnL of the EOD notional position. Computed by marking the EOD position to current price and comparing to EOD price mark.',
    },
    eodUnrealizedPnlHome: {
      field: 'position.PnLTags.EndOfDay.Equivalent.OpeningUnrealizedPnL',
      type: 'size',
      title: `EOD Position PnL (${homeCurrency})`,
      aggregate: true,
      hide: true,
      params: {
        currencyField: 'gridData.Equivalent.Currency',
        highlightNegative: true,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description: "PnL of the EOD notional position, converted to the user's home currency.",
    },
    eodDayTradePosition: {
      field: 'position.PnLTags.EndOfDay.TradePositionDelta',
      type: 'size',
      title: 'Day Trade Position',
      aggregate: true,
      params: {
        currencyField: 'gridData.Asset',
        highlightNegative: true,
        showCurrencyForNullPosition: false,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description: 'Current notional position minus EOD notional position.',
    },
    eodDayTradePositionHome: {
      field: 'position.PnLTags.EndOfDay.Equivalent.TradePositionDelta',
      type: 'size',
      title: `Day Trade Position (${homeCurrency})`,
      aggregate: true,
      hide: true,
      params: {
        currencyField: 'gridData.Equivalent.Currency',
        highlightNegative: true,
      },
      description: "Current notional position minus EOD notional position, converted to the user's home currency",
    },
    eodDayTradePnl: {
      field: 'position.PnLTags.EndOfDay.TradeUnrealizedPnL',
      type: 'size',
      title: 'Day Trade PnL',
      aggregate: true,
      params: {
        currencyField: 'position.PnLCurrency',
        highlightNegative: true,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description:
        "PnL attributable to current day's trading activity. Computed by summing up the PnL of trades executed today, which is computed by comparing the current price mark against the traded price.",
    },
    eodDayTradeHome: {
      field: 'position.PnLTags.EndOfDay.Equivalent.TradeUnrealizedPnL',
      type: 'size',
      title: `Day Trade PnL (${homeCurrency})`,
      aggregate: true,
      hide: true,
      params: {
        currencyField: 'gridData.Equivalent.Currency',
        highlightNegative: true,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description: "PnL attributable to current day's trading activity, converted to the user's home currency.",
    },
    totalPnl: {
      field: 'position.eodTotalPnL',
      type: 'size',
      title: 'Total PnL',
      aggregate: true,
      params: {
        currencyField: 'position.PnLCurrency',
        highlightNegative: true,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description: 'Sum EOD Position PnL and Day Trade PnL.',
    },
    totalPnlHome: {
      field: 'position.eodTotalPnLEquivalent',
      type: 'size',
      title: `Total PnL (${homeCurrency})`,
      aggregate: true,
      hide: true,
      params: {
        currencyField: 'gridData.Equivalent.Currency',
        highlightNegative: true,
      } satisfies SizeColumnParams<CommonPortfolioGridData>,
      description: "Sum EOD Position PnL and Day Trade PnL, converted to the user's home currency.",
    },
    pnlConversionRate: isPerformanceGrid
      ? {
          field: 'position.Equivalent.PnLConversionRate',
          type: 'price',
          title: 'Quote Ccy Rate',
          hide: true,
          params: {
            quoteCurrencyField: 'gridData.Equivalent.Currency',
          } satisfies AgGridPriceProps<CommonPortfolioGridData>,
          description: "Quoted currency to user's home currency conversion rate.",
        }
      : undefined,
  } as const satisfies Record<string, undefined | ColumnDef<CommonPortfolioGridData>>;
}

export const usePnlTagColumns = (pmsGridType: 'breakdown' | 'performance', homeCurrency: string) => {
  const { showSubAccountPositionPnLTagColumns } = useFeatureFlag();

  const isPerformanceGrid = pmsGridType === 'performance';
  const extraColMapping = useMemo(() => {
    if (!showSubAccountPositionPnLTagColumns) {
      return {};
    }

    const mapping = getPnlTagColumns(isPerformanceGrid, homeCurrency);
    Object.keys(mapping).forEach(key => {
      if (!mapping[key]) {
        delete mapping[key];
      }
    });
    return mapping;
  }, [homeCurrency, isPerformanceGrid, showSubAccountPositionPnLTagColumns]);

  const visiblePnlTagColumns = useMemo(() => {
    if (!showSubAccountPositionPnLTagColumns) {
      return [];
    }
    const allColumnKeys: Array<keyof ReturnType<typeof getPnlTagColumns>> = [
      'avgCost',
      'eodPriceMark',
      'eodPosition',
      'eodUnrealizedPnl',
      'eodDayTradePosition',
      'eodDayTradePnl',
      'totalPnl',
    ];
    return allColumnKeys.filter(key => extraColMapping[key] !== undefined);
  }, [extraColMapping, showSubAccountPositionPnLTagColumns]);

  return {
    extraColMapping,
    visiblePnlTagColumns,
  };
};
