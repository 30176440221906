import type { PropsWithChildren } from 'react';
import { Box, type BoxProps } from './Core';

/**
 * A {@link Box} that will overflow its content and hide the overflow, using 2 divs for relative/absolute position wrapping.
 * - Commonly helpful for a container that needs to scroll within another container, using a flexbox-based dom size.
 * @props {@link BoxProps} outerProps - Props for the outer (relative-position) {@link Box}.
 * @props {@link BoxProps} innerProps - Props for the inner (absolute-position) {@link Box}.
 */
export const ScrollableBox = ({
  children,
  outerProps,
  innerProps,
}: PropsWithChildren<{
  outerProps?: BoxProps;
  innerProps?: BoxProps;
}>) => {
  return (
    <Box position="relative" {...outerProps}>
      <Box position="absolute" left="0" right="0" top="0" bottom="0" {...innerProps}>
        {children}
      </Box>
    </Box>
  );
};
