import {
  Box,
  Button,
  ButtonVariants,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  IndicatorDotVariants,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  useDynamicCallback,
  type DrawerProps,
  type Market,
  type MarketConfig,
  type MarketCredential,
} from '@talos/kyoko';
import { DrawerHeaderFlex } from '@talos/kyoko/src/components/Drawer/styles';
import { memo, useCallback, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useAddCredentialTab } from './tabs/AddCredentialGeneralTab';

type AddCredentialDrawerProps = {
  marketCredentials: MarketCredential[];
  marketConfigs: MarketConfig[];
  selectedMarket: Market | undefined;
  externalIP: string;
  onSaved: () => void;
  onMarketSelected: (market: Market | undefined) => void;
};

export const AddCredentialDrawer = memo((props: AddCredentialDrawerProps & DrawerProps) => {
  const theme = useTheme();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);

  const [isSaving, setIsSaving] = useState(false);

  const { marketCredentials, marketConfigs, selectedMarket, externalIP, onSaved, onMarketSelected, close, isOpen } =
    props;

  const generalTab = useAddCredentialTab({
    marketCredentials,
    marketConfigs,
    selectedMarket,
    externalIP,
    onMarketSelected,
  });

  const viewableTabs = useMemo(() => {
    return [generalTab];
  }, [generalTab]);

  const handleSaveChanges = useCallback(async () => {
    try {
      setIsSaving(true);
      for (const [tabIndex, tab] of viewableTabs.entries()) {
        if (tab.isDirty) {
          await tab.save().catch(e => {
            setActiveTabIndex(tabIndex); // there was an error in this tab, so we need to show it
            throw e; // rethrow to stop the loop
          });
        }
      }
      onSaved?.();
    } catch (e) {
      // ignore
    } finally {
      setIsSaving(false);
    }
  }, [viewableTabs, onSaved]);

  const isDirty = viewableTabs.some(tab => tab.isDirty);
  const isDirtyMap = new Map(viewableTabs.map(tab => [tab.name, tab.isDirty]));

  const handleClose = useDynamicCallback(() => {
    if (!isDirty) {
      onMarketSelected(undefined);
      return close();
    }

    const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      onMarketSelected(undefined);
      close();
    }
  });

  return (
    <Drawer {...props}>
      <Prompt when={isOpen && isDirty} message="You have unsaved changes. Are you sure you want to leave?" />
      <DrawerHeaderFlex>
        <Flex gap="spacingDefault" alignItems="center">
          <Text color="colorTextImportant">Add Credential</Text>
        </Flex>
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
      </DrawerHeaderFlex>
      <DrawerContent>
        <Tabs w="100%" h="100%" selectedIndex={activeTabIndex} onSelect={handleTabChange} size={TabSize.Large}>
          <TabList isBordered ml={-theme.spacingComfortable}>
            {viewableTabs.map(tab => (
              <Tab
                showDot={isDirtyMap.get(tab.name)}
                dotVariant={IndicatorDotVariants.Primary}
                label={tab.name}
                key={tab.name}
              />
            ))}
          </TabList>
          <Box h="100%" pt="spacingMedium" overflow="auto">
            {viewableTabs.map(tab => (
              <Box
                key={tab.name}
                display={viewableTabs[activeTabIndex].name === tab.name ? 'block' : 'none'}
                w="100%"
                h="100%"
              >
                {tab.component}
              </Box>
            ))}
          </Box>
        </Tabs>
      </DrawerContent>
      <DrawerFooter>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSaveChanges} disabled={!isDirty} variant={ButtonVariants.Primary} loading={isSaving}>
          Save
        </Button>
      </DrawerFooter>
    </Drawer>
  );
});
